.card {
  .css-4ld6o8-euiCard__children {
    margin: 0;
  }
  .euiPanel {
    padding: 20px 16px 16px;
    min-height: 322px;
    max-height: 322px;
  }
  .extra-action {
    gap: 1rem !important;
  }
  .accordion-group {
    .euiAccordion__buttonContent {
      font-size: 16px;
      font-weight: 500;
      font-family: "Inter", sans-serif;
    }
    .euiAccordion__children {
      padding: 1rem;
      p {
        line-height: 20px;
        font-size: 14px;
      }
    }
  }
  .card-content {
    min-height: 322px;
  }
  .action_title {
    .no-state-content {
        text-align: center;
    }
    .css-4ld6o8-euiCard__children {
      margin: 0;
      overflow: auto;
      height: 263px;
    }
  }
  .zero_state_action {
    p {
        font-size: 16px;
        font-weight: 600;
        font-family: "Inter", sans-serif;
        
    }
  }
  .zero-state-card {
    p {
      max-width: 75%;
      margin: auto;
      font-size: 14px;
      font-weight: 500;
      font-family: "Inter", sans-serif;
    }
  }
}
