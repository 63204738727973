.side-nav {
  width: 224px;
  min-height: 100vh;
  padding: 0 !important;

  ul.euiListGroup {
    padding: 0;
  }

  .logo-container {
    padding: 30px 10px 30px 15px;
  }

  h3,
  span.euiListGroupItem__label {
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-weight: 500;
  }

  .euiAccordion.euiCollapsibleNavGroup {
    border: none;
  }

  .euiAccordion__button:focus {
    text-decoration: none;
  }

  .euiCollapsibleNavGroup__heading:hover {
    text-decoration: none;
  }

  .euiAccordion__triggerWrapper,
  button.euiListGroupItem__button {
    padding: 1rem 10px 1rem 15px !important;

    &:hover,
    &:active,
    &:focus {
      background-color: #e6f1fa;
      text-decoration: none;
    }
  }

  button.euiListGroupItem__button.css-14avnfv-euiListGroupItem__inner-m-text-isActive-isClickable {
    background-color: #e6f1fa;
    color: #006bb8;
    text-decoration: none;

    path {
      fill: #006bb8;
    }
  }

  .single-nav-item {
    .euiListGroupItem__label {
      padding-left: 10px !important;
    }
  }

  .nested-nav-item {
    .euiListGroupItem__label {
      padding-left: 30px !important;
    }
  }

  .euiCollapsibleNavGroup__children {
    padding: 0;
  }

  .euiButtonIcon {
    margin: 0;
    width: 15px;
    height: 15px;
  }

  .euiListGroupItem {
    border-radius: 0;

    &.css-1n8d9is-euiListGroupItem__inner-m-text-isClickable {
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.popout-nav {
  width: 276px;
  min-height: 100vh;
  padding: 0 !important;

  ul.euiListGroup {
    padding: 0;
  }

  .euiPanel {
    padding: 0;
  }

  .popout-nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    svg {
      cursor: pointer;
    }
  }

  .euiFlyout__closeButton {
    display: none;
  }

  .logo-container {
    padding: 30px 10px 30px 15px;
  }

  h3,
  span.euiListGroupItem__label {
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-weight: 500;
  }

  .euiAccordion.euiCollapsibleNavGroup {
    border: none;
  }

  .euiAccordion__button:focus {
    text-decoration: none;
  }

  .euiCollapsibleNavGroup__heading:hover {
    text-decoration: none;
  }

  .euiAccordion__triggerWrapper,
  button.euiListGroupItem__button {
    padding: 1rem 10px 1rem 15px !important;

    &:hover,
    &:active,
    &:focus {
      background-color: #e6f1fa;
      text-decoration: none;
    }
  }

  button.euiListGroupItem__button.css-14avnfv-euiListGroupItem__inner-m-text-isActive-isClickable {
    background-color: #e6f1fa;
    color: #006bb8;
    text-decoration: none;

    path {
      fill: #006bb8;
    }
  }

  .single-nav-item {
    .euiListGroupItem__label {
      padding-left: 10px !important;
    }
  }

  .nested-nav-item {
    .euiListGroupItem__label {
      padding-left: 30px !important;
    }
  }

  .euiCollapsibleNavGroup__children {
    padding: 0;
  }

  .euiButtonIcon {
    margin: 0;
    width: 15px;
    height: 15px;
  }

  .euiListGroupItem {
    border-radius: 0;

    &.css-1n8d9is-euiListGroupItem__inner-m-text-isClickable {
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.navheader {
  width: 100%;
  min-height: 0;
  max-height: 130px;
  display: block;
}

.euiHeader.main-nav.css-sqbryr-euiHeader-static-default {
  background-color: #f7f8fc;
}

.dashboard {
  flex-direction: row !important;
}

.main-container {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  object-fit: contain;
  background-color: #f7f8fc;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  overflow: auto;
}

.euiPopover__arrow::before {
  border-color: transparent transparent #fff transparent !important;
}

.nav-header {
  background-color: #f7f8fc;
  min-height: 60px;
  button.euiButtonEmpty.euiHeaderSectionItemButton {
    block-size: 0;
    min-inline-size: 0;
  }
  .main-nav {
    background-color: #f7f8fc;
    padding-left: 16px;
    padding-right: 16px;
    .logo-container {
      background-color: #f7f8fc;
    }
    .hamburger-menu {
      width: 20px;
      height: 20px;
      margin-right: 1rem;
      fill: #056e9d;
      cursor: pointer;
    }
    .hidden-logo {
      visibility: hidden;
    }
  }
}

.profile-popover {
  font-family: Inter;
  font-size: 14px;
  .euiPanel {
    padding: 0;
  }
  width: 193px;
  padding: 1rem 0;
  ul {
    padding: 0;
  }
}

.main-content {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  background-color: #f7f8fc;
  flex-flow: wrap;
  align-content: stretch;
  justify-content: space-between;
  align-self: auto;
  align-items: stretch;
  width: auto;
  display: flex;
  padding-bottom: 16px;
}

.card {
  flex: 0 auto;
  justify-content: flex-start;
  align-self: auto;
  width: 100%;
  min-width: auto;
  max-width: 49%;
  max-height: 322px;
  min-height: 322px;
  display: block;
}

.fw-card {
  max-width: 100%;
  max-height: 100%;
}

.nav-callout {
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  p.euiTitle {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  svg {
    width: 24px;
    height: 24px;
    fill: #006bb8;
    margin: 0;
  }
  .euiText svg {
    fill: #231f20;
  }
  p {
    color: #231f20;
  }
}

@media screen and (max-width: 991px) {
  .side-nav {
    display: none;
  }

  .main-container {
    margin-top: 60px;
    padding-top: 16px;
  }

  .nav-header {
    background-color: #fff;
    min-height: auto;
    max-height: none;
    position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
    z-index: 4000;
    .main-nav {
      min-height: 60px;
      background-color: #fff;
      .logo-container {
        background-color: #fff;
      }
    }
  }

  .euiHeader.main-nav.css-sqbryr-euiHeader-static-default {
    background-color: #fff;
  }

  .main-content {
    flex-flow: wrap;
    justify-content: space-between;
    align-self: auto;
    margin-top: auto;
  }

  .card {
    max-width: none;
  }

  .nav-callout {
    background-color: #e6f1fa;
    min-height: 60px;
  }
}
