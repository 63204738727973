/* Profile Builder 2.0 CSS */

/* EUI theme styles */
@import '@elastic/eui/dist/eui_theme_light.css';

/* Vendor global styles */
@import './vendor/bootstrap-grid.css';

/* Global Styles */
body, html {
  font-family: 'Inter', sans-serif !important;
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  background-color: #fff !important;
  line-height: initial !important;
  color: #343741 !important;
}
.header-font {
  font-family: Poppins, sans-serif;
}

/* Overriding styles */
.euiFieldText:focus {
  background-image: none;
  box-shadow: inset 0 0 0 1px #07C; 
}
.euiBody-hasPortalContent {
  position: initial !important;
}
.euiFormLabel.euiFormLabel-isFocused {
  color: #1a1c21;
}
.euiPopover__arrow::before {
  border-color: transparent transparent #ccE4F5 transparent !important;
}
.euiTourFooter {
  border: none !important;
  background: none !important;
}
.customFlyout .euiButtonIcon {
  display: none;
}
.euiModal__closeIcon {
  display: none !important;
}
.euiTableRow:hover {
  background-color: #E6F9F7;
}
.customFlyout {
  z-index: 3001 !important;
}
.btn-text {
  font-size: 16px !important;
  margin-right: 1rem;
}
label {
  font-size: 16px !important;
}
.euiFieldText {
  font-size: 16px !important;
  background-color: #fff;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1);
}
/* input {
  font-size: 16px !important;
  background-color: #fff;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1);
} */

.euiFormControlLayoutIcons .euiIcon[data-icon-type="warning"] {
  display: none;
}

.euiIcon.css-h17o4q-euiIcon-m-danger {
  display: none;
}
button.euiButton.css-8utmkn-euiButtonDisplay-m-defaultMinWidth-fill-primary {
  font-size: 16px
}
.euiFormControlLayout {
  height: auto;
}
.btn-spacing {
  margin-right: 1rem;
}
.euiComboBox .euiComboBox__inputWrap {
  font-size: 16px !important;
  background-color: #fff !important;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1) !important;
  background-image: none !important;
}
.euiComboBox .euiComboBox-isOpen .euiComboBox_inputWrap {
  background-color: #fff !important;
  box-shadow: inset 0 0 0 2px rgba(0, 119, 204, 1) !important;
  background-image: none !important;
}
.euiFormHelpText {
  font-size: 16px !important;
  line-height: 1.5;
}
.input-size-child {
  flex: 1 1 auto;
}
.euiFormControlLayout--group {
  background-color: #5e646f !important;
}
.euiFormControlLayout--group:focus {
  border-color: #07C;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 119, 204, 1)
}
.euiComboBox {
  max-width: 428px;
}

/* MOBILE */
@media (min-width: 576px) {
  .euiFormRow, .euiFormControlLayout, input {
    max-width: 428px !important;
  }

  .euiFormRow, .euiFormControlLayout {
    max-width: 428px !important;
  }

  .euiComboBox {
    max-width: 428px !important;
  }

  .euiComboBox .euiComboBox__inputWrap {
    max-width: 428px !important;
  }

  .css-3n67oa-euiPopover-EuiInputPopover {
    max-inline-size: 428px !important;
  }

  .euiSuperSelectControl {
    max-width: 428px !important;
  }

  .input-size-child input {
    width: 192px;
  }
}
.euiSuperSelectControl.euiSuperSelect--isOpen__button {
  background-color: white;
  background-image: none;
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 2px rgba(0, 119, 204, 1);
}
.euiSuperSelectControl:focus {
  background-image: none;
  box-shadow: inset 0 0 0 1px rgba(94, 100, 111, 1);
}
.euiSuperSelect__item:hover {
  text-decoration: none;
  background-color: rgba(0, 109, 228, 0.1);
}
.euiSuperSelect__item {
  font-size: 16px;
}
.euiSuperSelectControl {
  background-color: #fff;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1);
  font-size: 16px;
}
.euiFormControlLayout .euiFormControlLayout__childrenWrapper .euiFieldText:focus {
  border-color: #07C;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 119, 204, 1)
}
.euiFieldText:invalid {
  border-color: #BD271E;
  outline: none;
  box-shadow: 0 0 0 2px rgba(189, 39, 30, 1);
  background-image: none;
  background-size: none;
}
button.euiButton.css-vdoabi-euiButtonDisplay-m-defaultMinWidth-isDisabled-fill-disabled {
  opacity: .4;
  color: white;
  background: #0077cc;
  font-size: 16px;
}

input:focus, 
textarea:focus, 
button:focus, 
select:focus {
    outline: none !important;
}

option:focus {
    outline: none !important;
}

.euiFormErrorText {
  display: none;
}
.input-size-small {
  max-width: 206px !important;
  background-color: #fff;
}

.input-size {
  max-width: 428px !important;
}

.euiFormLabel {
  line-height: 24px;
  margin-bottom: 6px;
}

.euiFormLabel.euiFormLabel-isInvalid {
  color: inherit;
}
.euiFormRow + .euiFormRow, .euiFormRow + .euiButton {
  margin-top: 0;
}
.euiButton {
  font-size: 16px;   
}

.euiButton .euiComboBoxPill--plainText {
  font-size: 16px;
}
.euiComboBoxPill--plainText {
  font-size: 16px;
}
.react-datepicker {
  display: none;
}
button[aria-selected="true"] {
  text-decoration: none !important;
}
button.euiFilterSelectItem {
  &:hover {
    background-color: rgba(0, 109, 228, 0.1);
    text-decoration: none;
  }
}
.euiContextMenuItem {
  border-top: 1px solid #F1F4FA; 
}
span.euiComboBoxOption__content {
  font-size: 16px;
  line-height: 24px;
}
span.euiBadge.euiComboBoxOption__enterBadge.css-1d0tqsn-euiBadge-hollow {
  display: none;
}